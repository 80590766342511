<template>
    <!-- Component: organisms/registration-form -->
    <div v-if="!loading">
        <div v-if="signUpError" class="error-message" v-html="signUpError"></div>

        <form action="#sign-up" v-on:submit.prevent="register">

            <div class="field">
                <label class="label" for="first-name">First Name</label>
                <div class="control">
                    <input class="input" type="text" id="first-name" v-model="registerParams.firstName" required>
                </div>
            </div>

            <div class="field">
                <label class="label" for="last-name">Last Name</label>
                <div class="control">
                    <input class="input" type="text" id="last-name" v-model="registerParams.lastName" required>
                </div>
            </div>

            <div class="field">
                <label class="label" for="username">Username</label>
                <div class="control">
                    <input class="input" type="text" id="username" v-model="registerParams.username" required>
                </div>
            </div>

            <div class="field">
                <label class="label" for="email">Email</label>
                <div class="control">
                    <input class="input" type="email" id="email" v-model="registerParams.email" required>
                </div>
            </div>

            <div class="field" v-if="!forCompany">
                <label class="label" for="phone">Phone</label>
                <div class="control">
                    <input class="input" type="text" id="phone" v-model="registerParams.phone">
                </div>
            </div>

            <div class="field">
                <label class="label" for="password">Password</label>
                <div class="control">
                    <input class="input" type="password" id="password" v-model="registerParams.password" required>
                </div>
                <PasswordRequirements :password="registerParams.password"/>
            </div>
             <div class="field">
                <label class="label" for="password">Confirm Password</label>
                <div class="control">
                    <input class="input" type="password" id="passwordValidate" v-model="registerParams.passwordValidate" required>
                </div>
                <PasswordRequirements :password="registerParams.passwordValidate"/>
            </div>

            <div class="field" v-if="!forCompany">
                <label class="label" for="industry">Industry</label>
                <div class="control">
                    <input class="input" type="text" id="industry" v-model="registerParams.industry">
                </div>
            </div>

            <div class="field" v-if="!forCompany">
                <label class="label" for="company">Company</label>
                <div class="control">
                    <input class="input" type="text" id="company" v-model="registerParams.companyName">
                </div>
            </div>

            <div class="field" v-if="!forCompany">
                <label class="label" for="isCompany">
                    <input class="checkbox" type="checkbox" id="isCompany" value="1" v-model="registerParams.isCompany">
                    Create Enterprise Account (you will be able to manage subscriptions for multiple company users)
                </label>
            </div>

            <div v-if="!forCompany && registerParams.isCompany">
                <div class="field">
                    <label class="label" for="company_url">Company Website URL</label>
                    <div class="control">
                        <input class="input" type="text" id="company_url" v-model="registerParams.companyUrl">
                    </div>
                </div>
                <div class="field">
                    <label class="label" for="numberOfSeats">Number of Seats Required</label>
                    <div class="control">
                        <input class="input" type="text" id="numberOfSeats" v-model="registerParams.numberOfSeats">
                    </div>
                </div>
                <div class="field">
                    <label class="label" for="companyAudience">Company Audience</label>
                    <div class="control">
                        <input class="input" type="text" id="companyAudience" v-model="registerParams.companyAudience">
                    </div>
                </div>
            </div>

            <br/>
            <div>
                <div class="field">
                    <label class="label" for="tos">
                        <input class="checkbox" type="checkbox" id="tos" v-model="registerParams.tos">
                        I agree to to <a href="https://app.mygovguide.com/terms-of-use" target="_blank">Terms of Use, SaaS Agreement</a> and the <a href="https://app.mygovguide.com/privacy-policy" target="_blank">Privacy Policy</a>.
                    </label>
                </div>
            </div>

            <div class="field">
                <input
                    type="submit"
                    class="button is-primary"
                    :value="forCompany ? 'Register User' : 'Continue Registration'"
                >
            </div>
        </form>

        <div v-if="signUpError" class="error-message" v-html="signUpError"></div>
    </div>

    <preloader v-else></preloader>
    <!-- End Component: organisms/registration-form -->
</template>

<script>
    import Preloader from "./preloader";
    import { useStore } from "vuex"
    import { reactive, ref, watchEffect } from 'vue'
    import { useRouter } from "vue-router";
    import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";
    import useUser from "@/helpers/useUser";
    import RequestHandler from "@/handler/RequestHandler";
    import PasswordRequirements from "./password-requirements";

    export default {
        name: 'registration-form',
        components: { PasswordRequirements, Preloader },
        props: ['forCompany'],
        emits: ['registered'],
        setup(props, { emit }) {
            const store = useStore();
            const loading = ref(true);
            const signUpError = ref(null);

            if (!props.forCompany) {
                const { redirectIfLoggedIn, userLoaded } = useUser();
                const router = useRouter();
                watchEffect(() => {
                    redirectIfLoggedIn(router);
                    if (userLoaded.value) {
                        loading.value = false;
                    }
                });
            } else {
                loading.value = false;
            }

            const registerParams = reactive({
                firstName: '',
                lastName: '',
                username: '',
                email: '',
                password: '',
                passwordValidate: '',
                companyName: '',
                isCompany: 0,
                companyUrl: '',
                numberOfSeats: '',
                companyAudience: '',
                industry: '',
                phone: '',
                tos: false,
            });

            const resetRegistrationParams = () => {
                registerParams.firstName = '';
                registerParams.lastName = '';
                registerParams.username = '';
                registerParams.email = '';
                registerParams.password = '';
                registerParams.companyName = '';
                registerParams.isCompany = 0;
                registerParams.companyUrl = '';
                registerParams.numberOfSeats = '';
                registerParams.companyAudience = '';
                registerParams.industry = '';
                registerParams.phone = '';
                registerParams.tos = false;
            };

            const login = () => {
                loading.value = true;
                store.dispatch(ACTION_TYPES.signIn, {
                    login: registerParams.username,
                    password: registerParams.password,
                }).catch(() => {a
                    emit('registered');
                    signUpError.value = 'Registration completed, please sign in';
                    loading.value = false;
                    resetRegistrationParams();
                });
            }

            const register = () => {
                if (!registerParams.companyName && registerParams.isCompany && !props.forCompany) {
                    signUpError.value = 'Company Name required';
                    return;
                }
                if (registerParams.password !== registerParams.passwordValidate){
                    signUpError.value = 'Passwords do not match';
                    return;
                }
                if (registerParams.tos !== true){
                    signUpError.value = 'You must agree to Terms of Use, SaaS Agreement and the Privacy Policy';
                    return;
                }
                loading.value = true;

                RequestHandler
                    .signUp({
                        forCompany: props.forCompany,
                        firstName: registerParams.firstName,
                        lastName: registerParams.lastName,
                        username: registerParams.username,
                        email: registerParams.email,
                        password: registerParams.password,
                        companyName: registerParams.companyName,
                        industry: registerParams.industry,
                        phone: registerParams.phone,
                        isCompany: registerParams.isCompany ? '1' : '0',
                        companyUrl: registerParams.companyUrl,
                        numberOfSeats: registerParams.numberOfSeats,
                        companyAudience: registerParams.companyAudience,
                        agreedTos: registerParams.tos ? Math.floor(new Date().getTime() / 1000) : '',
                    }, store.getters.csrf)
                    .then(() => {
                        signUpError.value = '';
                        if (props.forCompany) {
                            loading.value = false;
                            resetRegistrationParams();
                            emit('registered');
                        } else {
                            store.commit(MUTATION_TYPES.setNewlyRegistered, true);
                            login();
                        }
                    })
                    .catch(error => {
                        signUpError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                        loading.value = false;
                    })
                ;
            }

            return { loading, register, registerParams, signUpError };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .error-message {
        margin-bottom: 2em;
        font-style: italic;
        color: $red;
        margin-top: 2em;
    }

    .input {
        border: 2px solid $blue--dark;
    }

    .button {
        margin-top: 10px;
    }
</style>
